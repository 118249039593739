import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { useLocation } from "@reach/router"

import GridSection from "../components/GridSection"
import CardDonation from "../components/CardDonation"
// import CardShare from "../components/CardShare"
import SEO from "../components/SEO"
import Loading from "../components/Loading"
// import FamilySuggestions from "../components/FamilySuggestions"
import { logEventGA } from "../utils/log"

import { GET_GIFT_CARD_REDEEM_BY_REDEEM_ID } from "../queries/findGiftCardRedeemByRedeemId"

import styles from "../styles/modules/static-pages.module.scss"
import { formatFamilyNamesFromNeeds } from "../utils/format"
import { useNeedsContext } from "../context/needsContext"

const thankYou = family =>
  family ? `Thank you for sharing with ${family}!` : "Thank you for sharing!"

/**
 * Custom hook that extract session id from "id" querystring param.
 */
const useGiftCardRedeemId = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  return queryParams.get("id")
}

const Error = () => {
  return (
    <>
      <p className={`body-copy ${styles.paragraph}`}>
        Oops, looks like something went wrong :(
      </p>
      <p className={`tiny-body-copy ${styles.paragraph}`}>
        We couldn't find information on your donation. Please check your email
        for a receipt and confirmation.
      </p>
    </>
  )
}

const Success = () => {
  const redeemId = useGiftCardRedeemId()
  const { clearSelectedNeeds } = useNeedsContext()

  const { loading, data, error } = useQuery(GET_GIFT_CARD_REDEEM_BY_REDEEM_ID, {
    variables: {
      redeem_id: redeemId,
    },
  })

  const [giftCardRedeemNotFound, setGiftCardRedeemNotFound] = useState(null)
  const [family, setFamily] = useState(null)
  const [donationItem, setDonationItem] = useState(null)

  useEffect(() => {
    if (!data) return
    const { giftCardRedeem } = data

    setGiftCardRedeemNotFound(error || giftCardRedeem == null)
    if (giftCardRedeem) {
      if (giftCardRedeem[0].needs) {
        setDonationItem(giftCardRedeem[0].needs)

        clearSelectedNeeds()

        const formattedFamilyNames = formatFamilyNamesFromNeeds(
          giftCardRedeem[0].needs
        )
        setFamily(formattedFamilyNames)

        // logEventGA("purchase", {
        //   transaction_id: checkoutSession[0].checkout_id,
        //   currency: "USD",
        //   items: family,
        //   revenue: checkoutSession[0].needs
        //     .map(v => v.value)
        //     .reduce((e, i) => {
        //       return e + i
        //     }, 0),
        // })
      } else {
        setGiftCardRedeemNotFound(error)
      }
    } else {
      setGiftCardRedeemNotFound(error)
    }
  }, [data, error])

  return (
    <div style={{ backgroundColor: "#f6f1eb" }}>
      <div className="wrapper wrapper--centered">
        {giftCardRedeemNotFound && <Error />}
        {loading && <Loading />}
        {!loading && donationItem && (
          <section>
            <SEO title={"Thank you for sharing"} />
            <h2 className="x-large-body-copy">{thankYou(family)}</h2>
            <GridSection wrapperClassName>
              <ul>
                {donationItem.map(item => (
                  <li key={item.need_id}>
                    <CardDonation data={item} isClosed={true} />
                  </li>
                ))}
              </ul>
            </GridSection>
          </section>
        )}

        {/* <FamilySuggestions
          data={suggestedFamilies}
          title="Other families in need"
        /> */}
      </div>
    </div>
  )
}

export default Success
