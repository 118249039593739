import { gql } from "@apollo/client"

export const GET_GIFT_CARD_REDEEM_BY_REDEEM_ID = gql`
  query findGiftCardRedeemByRedeemId($redeem_id: uuid!) {
    giftCardRedeem: gift_card_redeems(
      where: { redeem_id: { _eq: $redeem_id } }
    ) {
      redeem_id
      needs {
        need_id
        name
        value
        description
        need_type
        is_available
        family {
          family_id
          display_name
        }
      }
    }
  }
`
